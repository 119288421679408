
@import url('https://fonts.googleapis.com/css?family=Quattrocento:400,700');

@font-face {
    font-family: 'Kingthings Calligraphica Italic Webfont woff';
    src: url('../assets/fonts/Kingthings_Calligraphica_Italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Kingthings Calligraphica Light Webfont woff';
    src: url('../assets/fonts/Kingthings_Calligraphica_Light-webfont.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Kingthings Calligraphica Light Webfont ttf';
    src: url('../assets/fonts/Kingthings_Calligraphica_Light-webfont.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Kingthings Petrock';
    src: url('../assets/fonts/Kingthings_Petrock.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

.dim-bg {
    background: rgba(11,11,11,0.6);
    padding: 15px;
    border-radius: 10px;
    font-weight: 700;
}

.container, .text-container {
    margin: 0 auto;
    position: relative;
    padding: 0 20px;
}

.text-container {
    max-width: 750px;
}

.inner-hero.text-container {
    margin-top: 50px;
    max-width: 1000px;
}

.container {
    max-width: 1140px;
}

.dark-logo {
    display: none;
}

section {
    background: #fff;
}

.pad {
    padding: 60px 0;
}

.hero-text-container.centered,
.centered {
    text-align: center;
}

header {
    top: 0px;
    color: #fff;
    padding: 15px 0;
    position: fixed;
    width: 100%;
    z-index: 9999;
    line-height: 0;

    .container {
        line-height: 0;
    }

    &.header-background {
        background-color: rgba(255,255,255,.95);
        color: #333;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
        transition: background-color 0.2s ease;
        text-shadow: none;

        a {
            color: #333;
            @media #{$mid-point} {
                &:hover {
                    border-bottom-color: rgba(80, 80, 80,.3);
                    border-bottom-width: 5px;
                }
            }
        }

        .dark-logo {
            display: inline;
        }

        .light-logo {
            display: none;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
        z-index: 1;
        position: relative;
        @media #{$mid-point} {
            height: 100%;
        }
        display: block;

        span {
            display: block;
            height: 100%;
        }

        &:hover {
            text-decoration: none;
        }
    }

    .company-name {
        font-size: 1.7em;
        display: inline-block;

        img {
            height: 30px;
            width: auto;
        }
    }
}

.content {
    background: #fff;
    min-height: 100vh;
}

.info img {
        max-height: 650px;
}

.info {
    padding: 0 0 100px 0;

    &:first-child {
        padding-top: 100px;
    }
}

.subtext {
    margin-top: 10px;
}


.cta {
    margin: 25px 0 0 0;
}

.page h2 {
    text-align: center;
}

blockquote {
    padding: 18px 25px;
    margin: 0;
    quotes: "\201C""\201D""\2018""\2019";
    font-style: italic;

    cite {
        display: block;
        font-weight: bold;
        margin: 10px 0 0 0;
    }

    p {
        display: inline;
    }
}

blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
}

.square-image {
    width: 200px;
    height: 200px;
    border-radius: 200px;
    background-size:cover;
    background-repeat:no-repeat;
    margin: 0 auto;
}

.page {
    margin-bottom: 0;
    padding-bottom: 80px;
}

.center-text {
    text-align: center;
}


.learn-more {
    text-align: center;
    font-size: 1.4em;
    width: 115px;
    margin-left: auto;
    margin-right: auto;

    a {
        text-decoration: none;
        color: #fff;
        opacity: .9;

        &:hover {
            opacity: 1;
        }
    }
    .arrow {
        font-size: .6em;
    }
}

@media screen and (min-width: 1200px) {
    .learn-more {
        position: absolute;
        left: 48%;
        bottom: 5px;
    }
}

.editor-link {
    display: none;
    margin-top: 0;
    padding-top: 30px;
    .btn {
        border: 0;
        border-radius: 2px;
        width: 100%;
        max-width: 500px;
        box-sizing: border-box;
        font-size: 2rem;
        text-decoration: none;
        padding: 10px 15px;
        margin: 0;
        font-size: 18px;
        cursor: pointer;
        background-color: #f7e064;
        color: #333;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

        &:hover {
            background-color: #f4d525;
            color: #333;
        }
    }

}

.cms-editor-active .editor-link {
    display: block;
}

.header_btm_h1 {
    margin-top: 0px;
    font-family: 'Kingthings Petrock', sans-serif;
    color: #0b1b3a;
    font-size: 52px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
    line-height: 1.1;
}

.header.front {
    min-height: 271px;
    margin-bottom: 70px;
    background-color: #F7F7F2;
}

.header_h4 {
    font-family: Quattrocento, sans-serif;
    color: #8f9896;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 14px;
}

.grid-container {
    @media screen and (min-width: 890px) {
        display: grid;
        grid-template-columns: auto auto;
    }
}

.centered {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}


.short-header {
    @extend .hero;
    min-height: 400px;
}

.short-header-container {
    @extend .container;
    box-sizing: content-box;
    margin: -200px auto 10px auto;
    border-radius: 5px;

    h1 {
        font-family: "Kingthings Petrock", sans-serif;
    }
    h2 {
        font-family: "Kingthings Petrock", sans-serif;
    }
}

.group-image {
    width: 350px;
}

hr {
    width: 50%;
    border-style: ridge;
}

.left-header {
    margin-top: 12px;
    text-align: left;
    border-bottom: 2px solid black;
    background-image: linear-gradient(to right, #b6fbff, #83a4d4);
    border-radius: 10px;
    padding-left: 10px;
}

.spacer10 {
    height: 10px;
    width: 100px;
}

.img-left-wrap {
    float: left;
    margin: 10px;
    width: 30%;

    @media (max-width: 600px) {
        width: 100%;
        margin: 0;
    }
}

.short-header-container {
    @media (max-width: 400px) {
        margin-top: -20px !important;
    }
}

#google-map-fairgrounds {
    @media (max-width: 400px) {
        width: 99vw !important;
        margin-left: -20px;
    }
}
