.nav-open nav {
    border-bottom: 1px dotted rgba(255, 255, 255, .2);
    padding: 10px 0;
    a {
        display: block;
        padding: 30px 0;
    }

    @media #{$mid-point} {
        border: 0;
        padding: 0 20px;

        a {
            display: inline;
        }
    }
}

nav {
    text-transform: uppercase;
    font-size: .8em;
    width: 100%;
    position: relative;
    top: -7px;
    text-shadow: none;

    @media #{$mid-point} {
        padding: 0 20px;
        display: inline;
    }


    a {
        margin: 0 3px;
        padding: 14px 10px;
        border: 0;
        display: none;

        @media #{$mid-point} {
            display: inline;
            border-bottom: 1px solid rgba(255,255,255,0);
            color: rgba(255,255,255,.8);
        }


        &.nav-toggle {
            display: inline;
            position: absolute;
            right: 10px;
            top: -7px;
            font-size: 1.9em;
            border: 0;
            text-shadow: 0;
            padding: 0 !important;
            @media #{$mid-point} {
                display: none;
            }

            &:hover {
                border: 0;
            }
        }
    }

    a.active {
        border-bottom: 5px solid #ffa74d;
    }

}


.top-bar-section {
    display: block;
    height: 50px;
    min-height: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-image: -webkit-linear-gradient(270deg, #0b1b3a, #0b1b3a);
    background-image: linear-gradient(180deg, #0b1b3a, #0b1b3a);
}

.top-bar-wpr {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    min-height: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}
.top-bar_left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    height: 50px;
    min-height: 50px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.top-bar_right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    height: 50px;
    min-height: 50px;
    float: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}
ul.social-top {
    position: relative;
    height: 25px;
    margin-bottom: 0;
}

.top-bar_p {
    font-family: Quattrocento, sans-serif;
    font-weight: 400;
    color: #a8b3c6;
    font-size: 16px;
    letter-spacing: 0.12em;
}

ul.social-top li, ul.social-top li a {
    display: inline-block;
    height: 25px;
}
ul.social-top {
    margin: 0 0 20px 0;
    list-style: none;
}

.mobile-menu {
  display: none;
}

.grid-container {
    position: relative;
    max-width: 1280px;
}

.grid-container.fluid {
  padding-right: 90px;
  padding-left: 90px;
}

.grid-container.small {
        max-width: 1280px;
}
