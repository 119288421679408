footer {
	font-size: .6em;
	position: relative;
	background: #2b2b40;
	color: #fff;

	&,
	a {
		color: #999;
	}

	a {
		text-decoration: none;
		padding: 0 10px;

		&:hover {
			color: #fff;
		}
	}

	ul {
		padding: 0;
		margin: 0;

		&.footer-right-links {
			float: left;
			@media #{$tablet} {
				float: right;
			}
		}

		&.footer-left-links {
			float: left;
		}

		li {
			list-style: none;
			margin: 15px auto;
			display: inline-block;

			a {

				&:hover {
					text-decoration: none;
				}
				svg {
					fill: #999;
					transition: fill 0.2s ease;
					vertical-align: middle;
					width: 18px;
					height: 18px;
				}

				&:hover svg {
					fill: #fff;
				}

				&.twitter-icon:hover svg {
					fill: #55acee;
				}

				&.google-plus-icon:hover svg {
					fill: #db4437;
				}

				&.youtube-icon:hover svg {
					fill: #cd201f;
				}

				&.instagram-icon:hover svg {
					fill: #f167f5;
				}

				&.linkedin-icon:hover svg {
					fill: #0077b5;
				}

				&.pinterest-icon:hover svg {
					fill: #bd081c;
				}

				&.rss-icon:hover svg {
					fill: #f26522;
				}
			}
		}
	}
}

.copyright {
	margin: 15px auto;
	float: left;

	a {
		color: #999;
	}

	@media #{$desktop} {
		text-align: center;
		float: none;
		padding: 16px 0;
		margin: 0;
	}
}
