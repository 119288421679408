
// @font-face {
//   font-family: 'Manuskript Regular';
//   src: url('https://indyscotgamesandfest.com/wp-content/themes/JointsWP-CSS-master/assets/fonts/manuskript-regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }
$small: 450px;
$medium: 900px;

.hero {
    color: #ffffff;
    text-align: center;
    min-height: 100vh;
    // min-height: 550px;
    background-color: #333;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &:before {
        content: "";
        background: linear-gradient(to bottom,rgba(0,0,0,.65) 0,rgba(0, 0, 0, 0) 100%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 300px;
}

    p {
        color: #fff;
    }

    .inner-hero {
        // display: flex;
        align-items: center;
        height: 100%;
    }
    @media screen and (max-width: $small) {
        //do Smth
        max-width: 100%;
    }
    @media screen and (min-width: $medium) {
        //do Smth
        display: flex;
    }


    .hero-text-container {
        text-align: left;
        font-family: 'Kingthings Petrock', sans-serif;
        h1, h2, p, .button {
            text-shadow: 3px 3px 5px rgba(0,0,50,.9);
        }
        h1 {
            font-size: 3.8em;
        }
        h2 {
            font-size: 3.0em;
        }
    }

    .hero-text-right {
        position: absolute;
        right: 0;
    }
}

.image {
    text-align: center;
}

.bottom-cta {
    text-align: center;

    h2 {
        margin-bottom: 12px;
    }
}

@media #{$desktop} {
    .flex {
        @include flexbox;
        align-items: center;
        flex-direction: row;

        .text, .image {
            -webkit-flex: 1;
            flex: 1;
            padding: 0 20px;
        }
    }

    .content section:nth-child(even) .flex {
        flex-direction: row-reverse;
    }
}

.grid-item-title {
    grid-row-start: 1;
    grid-column-end: span 2;
    margin-top: min(100px) !important;
    margin-bottom: 0 !important;
    width: auto !important;
    text-align: center !important;
}
